'use client';

import NextError from 'next/error';

export default function GlobalError() {
   return (
      <html lang="en">
         <head>
            <meta
               name="viewport"
               content="width=device-width, initial-scale=1.0, viewport-fit=cover"
            />
         </head>

         <body>
            {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
            <NextError statusCode={0} />
         </body>
      </html>
   );
}

export const dynamic = 'force-dynamic';
